import React, { useEffect, useState } from 'react';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Rating } from '@mui/material';

const AddNewAudioBook = () => {
  const [catdata, setcatdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('maintoken')

  const navigate = useNavigate();
  const [data, setData] = useState({
    bookTitle: '',
    rating: '0',
    authorName: '',
    shortDescription: '',
    longDescription: '',
    imageUpload: null,
    uploadAudio: null,
    primaryCategory: '',
    secondaryCategory: '',
  });

  // Function to handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setData({ ...data, imageUpload: file });
  };

  // Function to handle audio file change
  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    setData({ ...data, uploadAudio: file });
  };

  const handleSeriesChange = (e) => {
    const selectedCategory = catdata.find(category => category._id === e.target.value);
    setData({ ...data, primaryCategory: selectedCategory ? selectedCategory._id : '' });
  };

  const handleSecChange = (e) => {
    const selectedCategory = catdata.find(category => category._id === e.target.value);
    setData({ ...data, secondaryCategory: selectedCategory ? selectedCategory._id : '' });
  };

  // Function to upload files
  const uploadFile = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post('https://backend.mysecretlit.com/api/uploadFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.data.path;
    } catch (error) {
      console.error(`Error uploading ${type}:`, error);
      toast.error(`Error uploading ${type}. Please try again.`);
      return "";
    }
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    let bookCoverImageUrl = "";
    let audioUrl = "";

    // Upload files if present
    if (data.imageUpload) bookCoverImageUrl = await uploadFile(data.imageUpload, "image");
    if (data.uploadAudio) audioUrl = await uploadFile(data.uploadAudio, "audio");

    // Construct payload
    const payload = {
      title: data.bookTitle,
      banner: "", // Add this if you have a banner value
      category: data.primaryCategory,
      downloads: "0",
      reads: "0",
      shortDescription: data.shortDescription,
      longDescription: data.longDescription,
      primaryCategory: data.primaryCategory,
      secondaryCategory: data.secondaryCategory,
      authorName: data.authorName,
      rating: data.rating,
      coverImage: bookCoverImageUrl, // Set the uploaded image URL
      type: "audioBook",
      mediaUrl: audioUrl, // Set the uploaded audio URL
      pdfUrl: "",
      epubUrl: "",
      kindleMobiUrl: ""
    };

    try {
      const response = await axios.post('https://backend.mysecretlit.com/api/book/addBook', payload,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Your Audio Book has been successfully added!');
      setTimeout(() => {
        navigate('/Dashboard/add-audio-books');
      }, 2000);
    } catch (error) {
      console.error('Error adding book:', error);
      if (error.response) {
        toast.error(error.response.data.message || 'Error adding book. Please try again.');
      } else if (error.request) {
        toast.error('No response from server. Please try again.');
      } else {
        toast.error('Error adding book. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch categories
  const fetchData = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getAudioCategories');
      const formattedData = response.data?.data?.reverse().map((item) => ({
        ...item,
        id: item._id,
      }));
      setcatdata(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <ToastContainer />
        <Navbar headingmain="Add New Audio Book" />
        <div style={{ width: '97%', margin: 'auto' }}>
          <div className="row-userDetails" style={{ width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Book Title</p>
              <input
                value={data.bookTitle}
                onChange={(e) => setData({ ...data, bookTitle: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Book Title'
              />
            </div>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Author Name</p>
              <input
                value={data.authorName}
                onChange={(e) => setData({ ...data, authorName: e.target.value })}
                className='inputfeild-userDetails'
                placeholder='Author Name'
              />
            </div>
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Short Description</p>
            <textarea
              value={data.shortDescription}
              onChange={(e) => setData({ ...data, shortDescription: e.target.value })}
              rows="2"
              className='inputfeild-userDetails'
              placeholder='Short Description'
            />
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Long Description</p>
            <textarea
              value={data.longDescription}
              onChange={(e) => setData({ ...data, longDescription: e.target.value })}
              rows="6"
              className='inputfeild-userDetails'
              placeholder='Long Description'
            />
          </div>
          <div className="row-userDetails" style={{ marginTop: '1em', width: '98%' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Primary Category</p>
              <select id="cars"
                value={data.primaryCategory}
                onChange={handleSeriesChange}
                style={{ textTransform: 'capitalize' }}
              >
                <option value=''>Select Primary Category</option>
                {catdata.map(category => (
                  <option key={category._id} value={category._id}>{category.name}</option>
                ))}
              </select>
            </div>

            <div className="input-feild-label">
              <p className="paragraph-details-user">Secondary Category</p>
              <select id="cars"
                value={data.secondaryCategory}
                onChange={handleSecChange}
                style={{ textTransform: 'capitalize' }}
              >
                <option value=''>Select Secondary Category</option>
                {catdata.map(category => (
                  <option key={category._id} value={category._id}>{category.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
             <p className="paragraph-details-user">Book Rating</p>
             <Rating
                name="rating"
                value={parseFloat(data.rating)}
                onChange={(e, newValue) => setData({ ...data, rating: newValue })}
                precision={0.5}
              />
          </div>
          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Upload Book Cover Image</p>
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {data.imageUpload && (
              <div>
                <h3>Selected Image:</h3>
                <img src={URL.createObjectURL(data.imageUpload)} alt="Selected" style={{ maxWidth: '100%', width: '16%' }} />
              </div>
            )}
          </div>

          <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
            <p className="paragraph-details-user">Upload Book Audio File</p>
            <input type="file" accept="audio/*" onChange={handleAudioChange} />
            {data.uploadAudio && (
              <div>
                <h3>Selected Audio:</h3>
                <audio controls>
                  <source src={URL.createObjectURL(data.uploadAudio)} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            )}
          </div>

          {loading && <div className="spinner-overlay">
            <Spinner animation="border" role="status" variant="danger">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>}
          
          <div className="row-userDetails" style={{ width: '98%' }}>
           
            <button  className='button-all-css' type="submit" onClick={handleSubmit} disabled={loading} style={{width:'100%',marginTop:'1em',marginBottom:'1em'}}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Add Book'}
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAudioBook;
