import React, { useEffect, useState } from 'react';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { useLocation, useNavigate } from 'react-router-dom'; // Added useNavigate
import axios from 'axios';
import { Spinner } from 'react-bootstrap'; // Importing the spinner component
import { Rating } from '@mui/material';

const AudioBookDetailsPg = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Initializing navigate
    const { data } = location.state; 
    const [catdata, setcatdata] = useState([]);
    const [loading, setLoading] = useState(false); // Spinner state

    const [dataa, setData] = useState({
        bookId: data?._id,
        title: data?.title || '',
        authorName: data?.authorName || '',
        longDescription: data?.longDescription || '',
        category: data?.category?._id || '',
        secondaryCategory: data?.secondaryCategory?._id || '',
        mediaUrl: data?.mediaUrl || '',        
        img: data?.coverImage || 'https://www.setantabooks.com/cdn/shop/products/IMG_9322_6e7219ba-55da-43ff-bac1-ba6bc68acb8d.jpg?v=1706971799&width=480',
        audioFile: null, 
        rating: data?.rating || 0,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...dataa, [name]: value });
    };
    
    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setData({ ...dataa, img: selectedImage });
    };
    
    const handleAudioChange = (e) => {
        const file = e.target.files[0];
        setData({ ...dataa, audioFile: file });
    };

    const uploadFile = async (file, type) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            
            const response = await axios.post('https://backend.mysecretlit.com/api/uploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.data.path; 
        } catch (error) {
            console.error(`Error uploading ${type}:`, error);
            return "";
        }
    };

    const handleSubmit = async () => {
        setLoading(true); // Show spinner when submission starts
        let bookCoverImageUrl = "";
        let audioFileUrl = "";
        const token = localStorage.getItem('maintoken');

        if (dataa.img instanceof File) {
            bookCoverImageUrl = await uploadFile(dataa.img, "image");
        }

        if (dataa.audioFile instanceof File) {
            audioFileUrl = await uploadFile(dataa.audioFile, "audio");
        }

        const payload = {
            bookId: dataa.bookId,
            title: dataa.title,
            authorName: dataa.authorName,
            category: dataa.category,
            secondaryCategory: dataa.secondaryCategory,
            mediaUrl: audioFileUrl || dataa.mediaUrl, 
            longDescription: dataa.longDescription,
            coverImage: bookCoverImageUrl || dataa.img,
            rating: dataa.rating,
        };
console.log('payload',payload)
        try {
            const response = await axios.post('https://backend.mysecretlit.com/api/updateBook', payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            navigate('/Dashboard/add-audio-books'); // Navigate to home page on success
        } catch (error) {
            console.error('Error updating book details:', error.response?.data || error.message);
            setLoading(false); // Hide spinner if error occurs
        }finally{
            setLoading(false); // Hide spinner after update
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://backend.mysecretlit.com/api/getAudioCategories');
            const formattedData = response.data?.data.map((item) => ({
                ...item,
                id: item._id
            }));
            setcatdata(formattedData);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <div className='list'>
            <Sidebar />
            <div className="listContainer">
                <Navbar headingmain="Audio Book Details" />
                <div className='all-book-style'>
                {/* Centered Spinner */}
                {loading && (
            <div className="spinner-overlay">
              <Spinner animation="border" variant="danger" />
            </div>
          )}

                <div style={{ width: '97%', margin: 'auto' }}>
                    <div className="row-userDetails" style={{ width: '98%' }}>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Book Title</p>
                            <input
                                value={dataa.title}
                                name="title"
                                onChange={handleInputChange}
                                className='inputfeild-userDetails'
                                placeholder='Book Title'
                            />
                        </div>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Author Name</p>
                            <input
                                value={dataa.authorName}
                                name="authorName"
                                onChange={handleInputChange}
                                className='inputfeild-userDetails'
                                placeholder='Author Name'
                            />
                        </div>
                    </div>

                    <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
                        <p className="paragraph-details-user"> Long Description</p>
                        <textarea
                            value={dataa.longDescription}
                            name="longDescription"
                            onChange={handleInputChange}
                            rows="6"
                            className='inputfeild-userDetails'
                            placeholder='Long Description'
                        />
                    </div>

                    <div className="row-userDetails" style={{ marginTop: '1em', width: '98%' }}>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Primary Category</p>
                            <select id='cars' name="category" value={dataa.category} onChange={handleInputChange} required>
                                {catdata?.map((category) => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="input-feild-label">
                            <p className="paragraph-details-user">Secondary Category</p>
                            <select id='cars' name="secondaryCategory" value={dataa.secondaryCategory} onChange={handleInputChange} required>
                                {catdata?.map((category) => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row-userDetails" style={{ marginTop: '1em' }}>
            <div className="input-feild-label">
              <p className="paragraph-details-user">Rating</p>
              <Rating name="simple-controlled" value={dataa.rating} onChange={(event, newValue) => setData({ ...dataa, rating: newValue })} />
            </div>
          </div>
                    <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
                        <p className="paragraph-details-user">Update Book Cover</p>
                        <div>
                            <input style={{ width: '100%' }} type="file" accept="image/*" onChange={handleImageChange} />
                            <img src={dataa.img instanceof File ? URL.createObjectURL(dataa.img) : dataa.img} 
                                 style={{ height: '300px', objectFit: 'contain', width: '20%', marginTop: 10 }} />
                        </div>
                    </div>

                    <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
                        <p className="paragraph-details-user">Upload Book Audio File</p>
                        <input type="file" accept="audio/*" onChange={handleAudioChange} />
                    </div>

                    <div className="input-feild-label" style={{ marginTop: '1em', width: '98%' }}>
                        <a
                            href={data?.uploadAudio?.url || dataa.mediaUrl}
                            download={`${data?.bookTitle?.replace(/\s+/g, '-') || dataa.title}.m4b`}
                            className="downlood-btn-audio-book"
                        >
                            Download Audio
                        </a>
                    </div>

                    <div className="ButtonDivPdf" id='check-pdf-right-btn'>
                        <audio className='audio-player-div-' id="audio-player" controls >
                            <source src={data?.uploadAudio?.url || dataa.mediaUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>

                    <button className='button-all-css' onClick={handleSubmit} style={{ marginTop: '2em', width: '100%' }}>
                        Update
                    </button>
                </div>
          </div>
            </div>
        </div>
    );
};

export default AudioBookDetailsPg;
