import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../list/list.scss";
import '../UserDetailsViewpg/UserDetails.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material'; // Assuming you use Material UI for loaders
import './app.css'
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Importing the eye and eye-slash icons
import { Button, Form } from "react-bootstrap";
import moment from 'moment-timezone';
const AccountSettings = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [subAdminUsername, setSubAdminUsername] = useState('');
  const [subAdminEmail, setSubAdminEmail] = useState('');
  const [subAdminPassword, setSubAdminPassword] = useState('');
  const [subAdminConfirmPassword, setSubAdminConfirmPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false); // State for loader
  const [alluser,setalluser] = useState([])
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState(false); // Toggle for old password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // Toggle for new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password visibility
  const [showSubAdminPassword, setShowSubAdminPassword] = useState(false); // Toggle for sub-admin password visibility
  const [showSubAdminConfirmPassword, setShowSubAdminConfirmPassword] = useState(false); // Toggle for sub-admin confirm password visibility
  const token = localStorage.getItem('maintoken'); // Retrieve token from local storage
  const formatDate = (dateString) => {
    const date = moment(dateString).tz('Asia/Karachi'); // Change to desired timezone
    return date.format('DD-MMM-YYYY'); // Change format as needed
  };
  useEffect(() => {
    const dashboardKey = localStorage.getItem("dashboard_key");
    console.log("asdasdas",dashboardKey)
    if (!dashboardKey) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, []);
  const handleLogin = async (e) => {
    try {
      const payload = {
        email:username,
        password:password
      };
      const response = await axios.post('https://backend.mysecretlit.com/api/admin/login', payload);
      const { accessToken} = response.data.data;
      localStorage.setItem("dashboard_key", accessToken);
      toast.success('Login successful');
      window.location.reload()
    
    } catch (error) {
      console.error('Error:', error); // Log the full error object for debugging
      if (error.response) {
        console.log(error.response.data);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log(error.request);
        toast.error('Server not responding');
      } else {
        console.log('Error', error.message);
        toast.error('Unexpected error occurred');
      }
    } 
  };
  // Fetch userId from localStorage on component mount
  useEffect(() => {
    const userDataString = localStorage.getItem('user');
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        if (userData && userData.user && userData.user._id) {
          setUserId(userData.user._id);
          console.log('User ID:', userData.user._id);
        } else {
          console.error('Invalid userData format or missing _id');
        }
      } catch (error) {
        console.error('Error parsing userData:', error);
      }
    } else {
      console.error('No userData found in localStorage');
    }
  }, []);

  const handlePasswordUpdate = () => {
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    const payload = {
      oldPassword:currentPassword,
      newPassword,
    };

    setLoading(true);

    axios.patch('https://backend.mysecretlit.com/api/updatePassword',payload,{ headers: {
      Authorization: `Bearer ${token}`,
    },})
    
      .then(response => {
        console.log('Password updated successfully:', response.data);
        toast.success('Password updated successfully');
        window.location.reload()
        // Optionally reset input fields or update UI
      })
      .catch(error => {
        console.error('Error updating password:', error.response?.data?.error);
        alert(error.response?.data?.error )
        toast.error(error.response?.data?.error);
        // Handle error, show error toast or message
      })
      .finally(() => {
        setLoading(false); // Hide loader
      });
  };

  const handleSubAdminSubmit = () => {
    // Validate passwords
    if (subAdminPassword !== subAdminConfirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    // Prepare payload for API
    const payload = {
      email:subAdminEmail,
      name: subAdminUsername,
      password: subAdminPassword,
      firstName: subAdminUsername,
      lastName: subAdminUsername,
      profilePicture: "/uploads/image.png",
      gender: "male"
    }

    // Example API call using Axios
    axios.post('https://backend.mysecretlit.com/api/admin/createSubAdmin', payload,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        console.log('Sub-admin user added successfully:', response.data);
        toast.success('Sub-admin user added successfully');
        window.location.reload()
        // Optionally reset input fields or update UI
      })
      .catch(error => {
        console.error('Error adding sub-admin user:', error);
        toast.error('Failed to add sub-admin user');
        // Handle error, show error toast or message
      })
      .finally(() => {
        setLoading(false); // Hide loader
      });
  };
  const fetchData = async () => {
    
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/admin/getSubAdmin',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setalluser(response.data.data.reverse())
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch books. Please try again.');
    } 
  };
  const handleDelete = async (id) => {
  
    try {
      const res = await axios.delete(`https://backend.mysecretlit.com/api/admin/deleteSubAdmin/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('This category is successfully deleted!'); // Show success toast
      window.location.reload()
    } catch (error) {
      console.error('Error making API call:', error);
      if (error.response) {
        console.log('Error Response Data:', error.response.data);
        toast.error(error.response.data.message || 'Error deleting category. Please try again.');
      } else if (error.request) {
        console.log('Error Request Data:', error.request);
        toast.error('No response from server. Please try again.');
      } else {
        console.log('Error Message:', error.message);
        toast.error('Error deleting category. Please try again.');
      }
    } 
  };
  useEffect(()=>{
    fetchData()
  },[])

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <Navbar headingmain='Account Settings' />

        {!loggedIn && (
          <div className="login-form">
            <h2>Login</h2>
            <Form>
              <Form.Group controlId="formBasicUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button  style={{width:'100%',marginTop:30}} className="edit-btn-mtn "  variant="primary" onClick={handleLogin}>
                Login
              </Button>
            </Form>
            <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
          </div>
        )}
        {loggedIn && (
<>

        <div className="datatable">
          <h2 style={{ fontSize: 22 }}>Admin Password</h2>
          <div className="input-feild-label">
            <p className="paragraph-details-user">Old Password</p>
            <input
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className='inputfeild-userDetails'
              placeholder='Old Password'
                          type={showCurrentPassword ? 'text' : 'password'}  // Toggle between text and password
            />
            <span className='eyespan'  onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />} {/* Icon changes based on toggle */}
            </span>
          </div>
          <div className="input-feild-label">
            <p className="paragraph-details-user">New Password</p>
            <input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className='inputfeild-userDetails'
              placeholder='New Password'
                           type={showNewPassword ? 'text' : 'password'}
            />
            <span className='eyespan' onClick={() => setShowNewPassword(!showNewPassword)}>
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="input-feild-label">
            <p className="paragraph-details-user">Confirm Password</p>
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className='inputfeild-userDetails'
              placeholder='Confirm Password'
              type={showConfirmPassword ? 'text' : 'password'}
                          />
                          <span className='eyespan' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
          </div>
          <button className='button-all-css' onClick={handlePasswordUpdate} style={{ marginTop: '1em' }}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Update Password'}
          </button>

          <h2 style={{ fontSize: 22, marginTop: '2em' }}>Add Sub-Admin User</h2>
          <div className="input-feild-label">
            <p className="paragraph-details-user">Username</p>
            <input
              value={subAdminUsername}
              onChange={(e) => setSubAdminUsername(e.target.value)}
              className='inputfeild-userDetails'
              placeholder='Username'
              type='text'
            />
          </div>
          <div className="input-feild-label">
            <p className="paragraph-details-user">Email</p>
            <input
              value={subAdminEmail}
              onChange={(e) => setSubAdminEmail(e.target.value)}
              className='inputfeild-userDetails'
              placeholder='Email'
              type='text'
            />
          </div>
          <div className="input-feild-label">
            <p className="paragraph-details-user">Password</p>
            <input
              value={subAdminPassword}
              onChange={(e) => setSubAdminPassword(e.target.value)}
              className='inputfeild-userDetails'
              placeholder='Password'
                          type={showSubAdminPassword ? 'text' : 'password'}
            />
            <span className='eyespan' onClick={() => setShowSubAdminPassword(!showSubAdminPassword)}>
              {showSubAdminPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="input-feild-label">
            <p className="paragraph-details-user">Confirm Password</p>
            <input
              value={subAdminConfirmPassword}
              onChange={(e) => setSubAdminConfirmPassword(e.target.value)}
              className='inputfeild-userDetails'
              placeholder='Confirm Password'
              type={showSubAdminConfirmPassword ? 'text' : 'password'}
            />
            <span className='eyespan' onClick={() => setShowSubAdminConfirmPassword(!showSubAdminConfirmPassword)}>
              {showSubAdminConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <button className='button-all-css' onClick={handleSubAdminSubmit} style={{ marginTop: '1em' }}>
            {/* {loading ? <CircularProgress size={24} color="inherit" /> : 'Add Sub-Admin'} */}
            Add Sub-admin-user
          </button>
        </div>
        <div style={{paddingLeft: 25}}>
              <h2 style={{ fontSize: 22 }}>Register All Sub-Admin Users List</h2>
              {alluser.length === 0 ? (
                <p>No users found.</p> // Message when no users are present
              ) : (
                alluser.map((item) => (
                  <div className='main-div-account-setting' key={item._id}>
                    <img style={{width: '2em', height: '2em', borderRadius: '50%'}} src='https://www.nea.org/sites/default/files/styles/1920wide/public/2021-01/Free-Book.jpg?itok=A0UCklYk' />
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                      <div>
                        <h6 className='h6-all-main-accoutn-setting'>Username</h6>
                        <p className="paragraph-details-user">{item?.name}</p>
                      </div>
                      <div>
                        <h6 className='h6-all-main-accoutn-setting'>Email</h6>
                        <p className="paragraph-details-user">{item?.email}</p>
                      </div>
                      <div>
                        <h6 className='h6-all-main-accoutn-setting'>Created Date</h6>
                        <p className="paragraph-details-user">{formatDate(item?.createdAt)}</p>
                      </div>
                      <button className='button-all-css' onClick={() => handleDelete(item._id)}>
                        Delete Sub-admin-user
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
</>
)}
    <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      </div>
    </div>
  );
};

export default AccountSettings;
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import { CircularProgress } from '@mui/material';
// import { Button, Form } from "react-bootstrap";
// import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Importing the eye and eye-slash icons
// import 'react-toastify/dist/ReactToastify.css';
// import moment from 'moment-timezone';
// import './app.css'

// const AccountSettings = () => {
//   const [currentPassword, setCurrentPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [subAdminPassword, setSubAdminPassword] = useState('');
//   const [subAdminConfirmPassword, setSubAdminConfirmPassword] = useState('');

//   const [showCurrentPassword, setShowCurrentPassword] = useState(false); // Toggle for old password visibility
//   const [showNewPassword, setShowNewPassword] = useState(false); // Toggle for new password visibility
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password visibility
//   const [showSubAdminPassword, setShowSubAdminPassword] = useState(false); // Toggle for sub-admin password visibility
//   const [showSubAdminConfirmPassword, setShowSubAdminConfirmPassword] = useState(false); // Toggle for sub-admin confirm password visibility
  
//   const [loading, setLoading] = useState(false);
//   const token = localStorage.getItem('maintoken');

//   const handlePasswordUpdate = () => {
//     if (newPassword !== confirmPassword) {
//       toast.error('Passwords do not match');
//       return;
//     }

//     const payload = {
//       oldPassword: currentPassword,
//       newPassword,
//     };

//     setLoading(true);

//     axios.patch('https://backend.mysecretlit.com/api/updatePassword', payload, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then(response => {
//         toast.success('Password updated successfully');
//         window.location.reload();
//       })
//       .catch(error => {
//         toast.error(error.response?.data?.error || 'Error updating password');
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   return (
//     <div className='list'>
//       <div className="listContainer">
//         <h2>Admin Password</h2>

//         <div className="input-feild-label">
//           <p>Old Password</p>
//           <div className="password-container">
//             <input
//               value={currentPassword}
//               onChange={(e) => setCurrentPassword(e.target.value)}
//               className='inputfeild-userDetails'
//               placeholder='Old Password'
//               type={showCurrentPassword ? 'text' : 'password'}  // Toggle between text and password
//             />
//             <span onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
//               {showCurrentPassword ? <FaEyeSlash /> : <FaEye />} {/* Icon changes based on toggle */}
//             </span>
//           </div>
//         </div>

//         <div className="input-feild-label">
//           <p>New Password</p>
//           <div className="password-container">
//             <input
//               value={newPassword}
//               onChange={(e) => setNewPassword(e.target.value)}
//               className='inputfeild-userDetails'
//               placeholder='New Password'
//               type={showNewPassword ? 'text' : 'password'}
//             />
//             <span onClick={() => setShowNewPassword(!showNewPassword)}>
//               {showNewPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>
//         </div>

//         <div className="input-feild-label">
//           <p>Confirm Password</p>
//           <div className="password-container">
//             <input
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//               className='inputfeild-userDetails'
//               placeholder='Confirm Password'
//               type={showConfirmPassword ? 'text' : 'password'}
//             />
//             <span onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
//               {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>
//         </div>

//         <button className='button-all-css' onClick={handlePasswordUpdate} style={{ marginTop: '1em' }}>
//           {loading ? <CircularProgress size={24} color="inherit" /> : 'Update Password'}
//         </button>

//         <h2>Add Sub-Admin User</h2>

//         <div className="input-feild-label">
//           <p>Password</p>
//           <div className="password-container">
//             <input
//               value={subAdminPassword}
//               onChange={(e) => setSubAdminPassword(e.target.value)}
//               className='inputfeild-userDetails'
//               placeholder='Password'
//               type={showSubAdminPassword ? 'text' : 'password'}
//             />
//             <span onClick={() => setShowSubAdminPassword(!showSubAdminPassword)}>
//               {showSubAdminPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>
//         </div>

//         <div className="input-feild-label">
//           <p>Confirm Password</p>
//           <div className="password-container">
//             <input
//               value={subAdminConfirmPassword}
//               onChange={(e) => setSubAdminConfirmPassword(e.target.value)}
//               className='inputfeild-userDetails'
//               placeholder='Confirm Password'
//               type={showSubAdminConfirmPassword ? 'text' : 'password'}
//             />
//             <span onClick={() => setShowSubAdminConfirmPassword(!showSubAdminConfirmPassword)}>
//               {showSubAdminConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>
//         </div>
//       </div>
//       <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} />
//     </div>
//   );
// };

// export default AccountSettings;
